import { Controller } from "@hotwired/stimulus"
import { DirectUploadController } from "@rails/activestorage"

function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

// Connects to data-controller="attachment-field"
export default class extends Controller {
  static targets = ["dropzone", "fileField", "attachedPrototype", "attachedContainer", "uploading", "directUploadContainer"]

  prototype = null
  uploads = []
  uploadInProgress = false

  connect() {
    this.prototype = this.attachedPrototypeTarget
    this.prototype.remove()
    this.prototype.classList.remove("hidden")

    this.fileFieldTarget.addEventListener("direct-upload:initialize", this.directUploadInitialized.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:progress", this.directUploadProgress.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:error", this.directUploadError.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:end", this.directUploadEnd.bind(this))
  }

  handleDragOver(event) {
    this.dropzoneTarget.classList.add("bg-roots-green-300")
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.effectAllowed = 'copy'
  }

  handleDrop(event) {
    event.preventDefault()
    const files = event.dataTransfer.files
    const fileInput =  this.fileFieldTarget
    this.dropzoneTarget.classList.remove("bg-roots-green-300")
    fileInput.files = files
    this.upload()
  }

  upload() {
    this.uploads.push(...this.createDirectUploadControllers())

    if (this.uploadInProgress) {
      // there's already uploads in progress
      return
    }

    this.uploadInProgress = true

    const startNextController = (callback) => {
      const controller = this.uploads.shift()

      if (controller) {
        controller.start(() => {
          startNextController(callback)
        })
      } else {
        callback()
        // this.dispatch("end")
      }
    }

    // this.dispatch("start")
    this.fileFieldTarget.dataset.enablesValue = "false"
    const event = new CustomEvent("attachment-field:uploads-start", {bubbles: true})
    this.fileFieldTarget.dispatchEvent(event)

    startNextController(() => {
      this.uploadInProgress = false

      this.fileFieldTarget.dataset.enablesValue = "true"
      this.fileFieldTarget.value = null

      const event = new CustomEvent("attachment-field:uploads-complete", {bubbles: true})
      this.fileFieldTarget.dispatchEvent(event)
    })
  }

  deleteUpload(event) {
    const upload = event.target.closest("[data-attachment-field-target='uploading']")
    const id = upload.dataset.directUploadId

    const directUploads = Array.from(this.directUploadContainerTarget.querySelectorAll(".direct-upload"))
    const index = directUploads.findIndex(upload => upload.id === `direct-upload-${id}`)

    const directUploadInputs = Array.from(
      this.directUploadContainerTarget.querySelectorAll("input[type='hidden']")
    ).filter(input => input.value.length)

    directUploads[index].remove()
    directUploadInputs[index].remove()
    upload.remove()
  }

  createDirectUploadControllers() {
    const controllers = []
    const files = this.fileFieldTarget.files
    Array.from(files).forEach(file => {
      const controller = new DirectUploadController(this.fileFieldTarget, file)
      controllers.push(controller)
    })
    return controllers;
  }

  directUploadInitialized(event) {
    const { id, file } = event.detail

    const uploading = this.prototype.cloneNode(true)
    uploading.setAttribute("data-attachment-field-target", "uploading")
    uploading.dataset.directUploadId = id
    uploading.querySelector("[data-target='name']").textContent = `${file.name} (queued)`
    uploading.querySelector("[data-target='size']").textContent = getReadableFileSizeString(file.size)
    uploading.querySelector("[data-target='progress']").style.width = "0%"
    this.attachedContainerTarget.appendChild(uploading)
  }

  directUploadProgress(event) {
    const el = this.getAttachedUpload(event.detail.id)
    el.querySelector("[data-target='name']").textContent = `${event.detail.file.name} (uploading)`
    el.querySelector("[data-target='progress']").style.width = `${event.detail.progress}%`
  }

  directUploadError(event) {
    Honeybadger.notify(event.detail.error)

    const el = this.getAttachedUpload(event.detail.id)
    const tooltipEl = el.querySelector("[data-popover]")
    tooltipEl.querySelector("[data-target='message']").textContent = event.error
    tooltipEl.setAttribute("id", `upload-error-${event.detail.id}`)

    const errorEl = el.querySelector("[data-target='error']")
    errorEl.classList.remove("hidden")
    errorEl.setAttribute("data-popover-target", `upload-error-${event.detail.id}`)
    initPopovers()
  }

  directUploadEnd(event) {
    const el = this.getAttachedUpload(event.detail.id)
    el.querySelector("[data-target='name']").textContent = event.detail.file.name
    el.querySelector("[data-target='size']").classList.remove("hidden")
    el.querySelector("[data-target='deletebtn']").classList.remove("hidden")
    el.querySelector("[data-target='progress']").classList.add("hidden")
  }

  getAttachedUpload(id) {
    return this.uploadingTargets.find(uploading => uploading.dataset.directUploadId == id)
  }
}
