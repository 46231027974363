import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visit"
export default class extends Controller {
  static values = {
    url: String,
    options: Object
  }

  static targets = ['urlSource']

  connect() {
  }

  visit(event) {
    let url = this.urlValue
    let options = { ...(this.optionsValue || {}) }

    if (!url) {
      if (this.hasUrlSourceTarget) {
        const checked = this.urlSourceTargets.find((src) => src.checked)
        if (checked) {
          if (checked.dataset.visitRequestSubmit) {
            checked.closest('form').requestSubmit()
            return
          }

          url = checked.dataset.visitUrl
          options = { ...options, ...(checked.dataset.visitOptions || {}) }
        }
      }
    }

    if (!url) {
      console.error('URL value not defined')
      return
    }

    Turbo.visit(url, options) 
  }
}
